
import { Vue, Component, Model, Prop } from "vue-property-decorator";
import { mdiChevronDown, mdiChevronUp, mdiPencilOutline, mdiCheckOutline, mdiClose } from "@mdi/js";
import { mapValues } from "lodash";
import ThresholdSliderField from "@/components/ThresholdSliderField.vue";
import setThresholdsMutation from "@/gql/set-thresholds-mutation";
import StandardDialog from "@/components/StandardDialog.vue";
import AssetPropertyAlertConfigs from "@/components/AssetPropertyAlertConfigs.vue";
import { Format, MetricIndicatorType, ThresholdPair, Thresholds, UnitConversionFn } from "@/types";
import { formatNumber } from "@/utils/number";
import { fitBoundsToThresholds } from "@/utils/number";
import { convertThresholdsToPairs } from "@/utils/models";
import features from "@/config/features";

@Component({
  components: {
    ThresholdSliderField,
    StandardDialog,
    AssetPropertyAlertConfigs
  }
})
export default class ThresholdSlider extends Vue {
  @Model("input", { type: Object, required: true })
  readonly thresholds: Thresholds;

  @Model("input", { type: Array, required: true })
  readonly thresholdArray: ThresholdPair[];

  @Prop({ type: String, required: true })
  readonly name: string;

  @Prop({ required: true })
  readonly value: number | undefined;

  @Prop({ type: String, default: "integer" })
  readonly format: Format;

  @Prop({ default: MetricIndicatorType.Plain })
  readonly indicatorType: MetricIndicatorType;

  @Prop({ type: String })
  readonly label: string | undefined;

  @Prop({ type: Number, default: 0 })
  readonly min: number;

  @Prop({ type: Number, required: true })
  readonly max: number;

  @Prop({ type: String })
  readonly unit: string | undefined;

  @Prop({ type: Boolean, default: false })
  readonly fitBounds: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly: boolean;

  @Prop({ type: String, default: "inPlace" })
  readonly editMode: string;

  @Prop({ type: Function })
  readonly deconvertValueFn: UnitConversionFn;

  features = features;
  editing = false;
  saving = false;
  thresholdState: Thresholds = { lowCritical: null, lowWarning: null, highWarning: null, highCritical: null };

  icons = { mdiChevronDown, mdiChevronUp, mdiPencilOutline, mdiCheckOutline, mdiClose };
  showSlideDown = false;

  mounted(): void {
    // are we drilling into this property?
    if (this.$route.params.property && this.$route.params.property == this.name) {
      // default the slide down open and scroll to the parent card
      this.showSlideDown = true;
      const nameRef = this.$refs[this.name] as HTMLElement;
      nameRef?.closest(".v-card")?.scrollIntoView({ behavior: "smooth" });
    }
  }

  startEditing(): void {
    this.editing = true;
    this.saving = false;
    this.thresholdState = { ...this.thresholds };
  }

  saveChanges(): void {
    this.saving = true;

    const deconvert = this.deconvertValueFn;
    const convertedThresholds = mapValues(this.thresholdState, value => {
      return deconvert ? deconvert(value) : value;
    });
    const thresholds = convertThresholdsToPairs(convertedThresholds);

    setThresholdsMutation("UBX_ASSET", this.$route.params.assetUuid, this.name, thresholds)
      .then(response => {
        if (response.statusCode === 200) {
          this.editing = false;
          this.saving = false;
          this.$store.commit("showSnackbar", { color: "success", key: "messages.save_succeeded" });
          this.$emit("success", { propertyName: this.name, thresholds });
        } else {
          this.submitFailed();
        }
      })
      .catch(() => {
        this.submitFailed();
      });
  }

  submitFailed(): void {
    this.saving = false;
    this.$store.commit("showSnackbar", { color: "warning", key: "messages.save_failed" });
  }

  cancelChanges(): void {
    this.editing = false;
    this.saving = false;
    this.thresholdState = { ...this.thresholds };
  }

  updateSubscriptionCount = (subscriptionCount: number, propertyName: string): void => {
    this.$emit("update-subscription-count", subscriptionCount, propertyName);
  };

  get sliderProps(): Record<string, any> {
    const fitToThresholds = !(this.editMode === "inPlace" && this.editing);
    const [min, max] = this.bounds(fitToThresholds);

    return {
      value: this.value,
      format: this.format,
      indicatorType: this.indicatorType,
      min,
      max
    };
  }

  get dialogSliderProps(): Record<string, any> {
    const fitToThresholds = !(this.editMode === "dialog" && this.editing);
    const [min, max] = this.bounds(fitToThresholds);

    return {
      value: this.value,
      format: this.format,
      indicatorType: this.indicatorType,
      min,
      max
    };
  }

  bounds(fitToThresholds: boolean): [number, number] {
    if (this.fitBounds && fitToThresholds) {
      return fitBoundsToThresholds(this.min, this.max, this.thresholdArray, this.value);
    }
    return [this.min, this.max];
  }

  get formattedValue(): string {
    return formatNumber(this.value, { format: this.format }) ?? "--";
  }

  get hideCancel(): boolean {
    return !this.editing || this.saving;
  }

  get showThresholdDialog(): boolean {
    return this.editMode === "dialog" && this.editing;
  }
}
