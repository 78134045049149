
import { Vue, Component, Prop, Model, Watch } from "vue-property-decorator";
import VueSlider from "vue-slider-component";
import "@/styles/vue-slider-component.scss";
import { mdiPencilOutline, mdiCheckOutline, mdiClose } from "@mdi/js";
import { intersection, zipObject, isNil, clamp, ceil } from "lodash";
import ValidatedForm from "@/components/ValidatedForm.vue";
import FormField from "@/components/FormField.vue";
import { FormConfig, FieldConfig, Format, MetricIndicatorType, Thresholds } from "@/types";
import { formatConfig } from "@/utils/number";
import { convertThresholdsToPairs, determinePropertyStatus } from "@/utils/models";
import { readFieldConfig, readFormConfig } from "@/config/form";

const GREEN = "#43a047";
const YELLOW = "#fbc02d";
const RED = "#e53935";
const GREY = "#9e9e9e";

const INDICATOR_COLORS: Record<MetricIndicatorType, string> = {
  [MetricIndicatorType.Nominal]: GREEN,
  [MetricIndicatorType.Warning]: YELLOW,
  [MetricIndicatorType.Critical]: RED,
  [MetricIndicatorType.Plain]: "black",
  [MetricIndicatorType.Stale]: GREY
};

const LOW_THRESHOLD_NAMES = ["lowCritical", "lowWarning"];
const HIGH_THRESHOLD_NAMES = ["highWarning", "highCritical"];
const THRESHOLD_NAMES = [...LOW_THRESHOLD_NAMES, ...HIGH_THRESHOLD_NAMES];
const THRESHOLD_COLORS = zipObject(THRESHOLD_NAMES, [RED, YELLOW, YELLOW, RED]);

const TRIANGLE_SIZE = 1.0;

@Component({
  components: {
    VueSlider,
    ValidatedForm,
    FormField
  }
})
export default class ThresholdSliderField extends Vue {
  @Model("input", { type: Object, required: true })
  readonly thresholds: Record<string, number>;

  @Prop({ required: true })
  readonly value: number | undefined;

  @Prop({ type: String, default: "integer" })
  readonly format: Format;

  @Prop({ default: MetricIndicatorType.Plain })
  readonly indicatorType: MetricIndicatorType;

  @Prop({ type: Number, default: 0 })
  readonly min: number;

  @Prop({ type: Number, required: true })
  readonly max: number;

  @Prop({ type: Boolean, default: false })
  readonly showTextFields: boolean;

  @Prop({ type: Boolean, default: false })
  readonly editing: boolean;

  @Prop({ type: Boolean, default: false })
  readonly compact: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean;

  textThresholds: Record<string, number | string> = {};

  icons = { mdiPencilOutline, mdiCheckOutline, mdiClose };
  readonly GREEN = GREEN;

  created(): void {
    this.textThresholds = { ...this.thresholds };
  }

  handleBlur(event: Record<string, any>): void {
    const { name: field, validationProps } = event;
    if (validationProps.invalid) return;

    const strVal = this.textThresholds[field].toString();
    const value = parseFloat(strVal);

    const newThresholds = { ...this.thresholds };
    const [lowerThresholds, higherThresholds] = this.sliderThresholdsBySide(field);

    this.adjustThresholds(newThresholds, value, lowerThresholds, -1);
    this.adjustThresholds(newThresholds, value, higherThresholds, 1);

    newThresholds[field] = value;
    this.$emit("input", newThresholds);
  }

  get thresholdNames(): string[] {
    return THRESHOLD_NAMES.filter(t => this.hasField(t));
  }

  get thresholdsValues(): number[] {
    return this.thresholdNames.map(t => this.thresholds[t]);
  }

  set thresholdsValues(thresholds: number | number[]) {
    const array = Array.isArray(thresholds) ? thresholds : [thresholds];
    this.$emit("input", zipObject(this.thresholdNames, array));
  }

  @Watch("thresholds")
  syncFieldsWithSlider(): void {
    this.textThresholds = { ...this.thresholds };
  }

  processColors(positions: number[]): [number, number, Record<string, any>][] {
    const lowThresholds = intersection(this.thresholdNames, LOW_THRESHOLD_NAMES);
    const highThresholds = intersection(this.thresholdNames, HIGH_THRESHOLD_NAMES);
    const lowColors = lowThresholds.map(t => THRESHOLD_COLORS[t]);
    const highColors = highThresholds.map(t => THRESHOLD_COLORS[t]);
    const lowPositions = positions.slice(0, lowThresholds.length);
    const highPositions = positions.slice(lowThresholds.length, lowThresholds.length + highThresholds.length);

    return [
      ...this.colorRanges([0, ...lowPositions], lowColors),
      ...this.colorRanges([...highPositions, 100], highColors)
    ];
  }

  colorRanges(positions: number[], colors: string[]): [number, number, Record<string, any>][] {
    return colors.map((color, index) => {
      return [positions[index], positions[index + 1], { backgroundColor: color }];
    });
  }

  get marks(): Record<string, any> | undefined {
    if (isNil(this.value)) return undefined;

    const value = clamp(this.value, this.effectiveMin, this.effectiveMax);

    const width = TRIANGLE_SIZE / 2;
    const height = (TRIANGLE_SIZE * Math.sqrt(3)) / 2;

    return {
      [value]: {
        label: undefined,
        style: {
          backgroundColor: "transparent",
          width: "0",
          height: "0",
          borderWidth: `0 ${width}rem ${height}rem ${width}rem`,
          transform: "translateY(-180%) translateX(-25%) rotate(60deg)",
          borderColor: `transparent transparent ${this.currentValueColor} transparent`,
          borderStyle: "solid",
          display: "block",
          borderRadius: 0
        },
        labelStyle: {
          fontSize: "1rem",
          fontWeight: "500"
        }
      }
    };
  }

  get currentValueColor(): string {
    const thresholdArray = convertThresholdsToPairs(this.thresholds as Thresholds);

    const indicatorType = !this.editing
      ? this.indicatorType
      : determinePropertyStatus(this.value ?? null, thresholdArray);
    return INDICATOR_COLORS[indicatorType];
  }

  get minRange(): number {
    return ceil((this.effectiveMax - this.effectiveMin) / 100, this.decimals);
  }

  get formConfig(): FormConfig {
    const fields: Record<string, FieldConfig> = {};
    for (const field of this.thresholdNames) {
      fields[field] = this.makeFieldConfig(field);
    }

    return readFormConfig({
      i18nNamespace: "thresholds",
      fields
    });
  }

  makeFieldConfig(field: string): FieldConfig {
    // Adjust this threshold's min and max to account for other thresholds "in the way"
    const [lowerThresholds, higherThresholds] = this.sliderThresholdsBySide(field);
    const min = this.effectiveMin + this.minRange * lowerThresholds.length;
    const max = this.effectiveMax - this.minRange * higherThresholds.length;

    const formatRule = this.decimals === 0 ? { integer: true } : {};

    return readFieldConfig({
      dataType: "number",
      disabled: this.disabled,
      rules: { required: true, between: { min, max }, ...formatRule }
    });
  }

  hasField(field: string): boolean {
    return !isNil(this.thresholds[field]);
  }

  adjustThresholds(
    newThresholds: Record<string, any>,
    value: number,
    higherThresholds: string[],
    direction: number
  ): void {
    if (higherThresholds.length === 0) return;

    let nextValue = newThresholds[higherThresholds[0]];
    if (direction === 1 ? value < nextValue : value > nextValue) return;

    nextValue = value + this.minRange * direction;
    newThresholds[higherThresholds[0]] = nextValue;
    this.textThresholds[higherThresholds[0]] = nextValue;
    higherThresholds.shift();

    this.adjustThresholds(newThresholds, nextValue, higherThresholds, direction);
  }

  sliderThresholdsBySide(field: string): [string[], string[]] {
    const thresholds = this.thresholdNames;
    const index = thresholds.indexOf(field);
    const higherThresholds = thresholds.slice(index + 1);
    const lowerThresholds = thresholds.slice(0, index).reverse();

    return [lowerThresholds, higherThresholds];
  }

  get effectiveMin(): number {
    const multiplier = Math.pow(10, this.decimals);
    return Math.floor(this.min * multiplier) / multiplier;
  }

  get effectiveMax(): number {
    const multiplier = Math.pow(10, this.decimals);
    return Math.ceil(this.max * multiplier) / multiplier;
  }

  get interval(): number {
    return 1 / Math.pow(10, this.decimals);
  }

  get decimals(): number {
    return formatConfig(this.format).maximumFractionDigits ?? 0;
  }
}
